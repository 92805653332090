import React from "react";
import { List, Box, Typography, Button } from "@material-ui/core";
import { ServiceCard } from "../index";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

export default function AdditionalServices(props) {
  const { toggleServiceDialogOpen, ...rest } = props;

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newArray = [...rest.values.additionalServices];

    newArray.splice(source.index, 1);
    newArray.splice(
      destination.index,
      0,
      rest.values.additionalServices.find(
        (service) => service.id === draggableId
      )
    );

    let newIndexes = newArray.map((item, index) => {
      return { ...item, index: index };
    });

    rest.setFieldValue("additionalServices", newIndexes);
  };

  return (
    <>
      <Box mx={3} maxWidth="670px">
        <Box mt={3} mb={4} display="flex">
          <Box width="60%" display="flex">
            <Typography variant="h6">Tilläggstjänster</Typography>
            <Box></Box>
          </Box>
          <Box width="40%" display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              style={{ color: "white", textTransform: "none" }}
              onClick={() => toggleServiceDialogOpen("create")}
            >
              Lägg till
            </Button>
          </Box>
        </Box>
        <Box mb={2}>
          {rest.values.additionalServices.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppabletwo">
                {(provided) => (
                  <List
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {rest.values.additionalServices.map((service, index) => (
                      <Box
                        onClick={() => toggleServiceDialogOpen(service.id)}
                        key={service.id}
                      >
                        <ServiceCard service={service} index={index} />
                      </Box>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Box maxWidth="400px">
              <Typography>
                Det finns inga tilläggstjänster registrerat, skapa ett nytt
                genom att klicka på lägga till.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
