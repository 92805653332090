import { Box, Chip, makeStyles } from "@material-ui/core";
import React from "react";
import { useContextApi } from "../../context/index";

const useStyles = makeStyles((theme) => ({
  chip: {
    color: "white",
    background: theme.palette.secondary.main,
  },
}));

export default function StatusChip(props) {
  const { activationDate } = props;
  const classes = useStyles();
  const { formatDate } = useContextApi();

  const today = formatDate(new Date(), "YYYY-MM-DDTHH:MM");
  const methodActivationDate = formatDate(activationDate, "YYYY-MM-DDTHH:MM");

  return (
    <Box visibility={methodActivationDate <= today ? "visible" : "hidden"}>
      <Chip size="small" className={classes.chip} label={"Aktiv"} />
    </Box>
  );
}
