import React, { useState, useEffect } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import axios from "axios";
import { Avatar } from "@material-ui/core";
import { useContextApi } from "../../context/index";
import { useStyles, NavDrawer } from "./index";

export default function Navbar(props) {
  const classes = useStyles();
  const { accounts, getGraphApiTokenRedirect } = useContextApi();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userAvatar, setUserAvatar] = useState({ imageUrl: "" });
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    async function callMsGraphApi() {
      await getGraphApiTokenRedirect()
        .then((res) => {
          axios({
            method: "GET",
            url: "https://graph.microsoft.com/v1.0/me/photo/$value",
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${res.accessToken}`,
            },
          })
            .then((response) => {
              const url = window.URL || window.webkitURL;
              const downloadUrl = url.createObjectURL(
                new Blob([response.data])
              );
              setUserAvatar({
                imageUrl: downloadUrl,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    callMsGraphApi();
  }, []);

  return (
    <div className={classes.navbarWrapper}>
      <div className={classes.navbarIcons}>
        <div className={classes.menuButton} onClick={toggleDrawer()}>
          <MenuIcon />
        </div>
        <div>
          <div className={classes.menuButton} onClick={toggleDrawer()}>
            {userAvatar && (
              <Avatar
                alt={accounts[0].name}
                src={userAvatar.imageUrl}
                className={classes.avatar}
              />
            )}
          </div>
        </div>
      </div>
      <NavDrawer
        drawerOpen={drawerOpen}
        accounts={accounts}
        toggleDrawer={toggleDrawer}
      />
    </div>
  );
}
