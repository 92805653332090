import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import InfoIcon from "@material-ui/icons/Info";
import {
  BaseInformation,
  Settings,
  Options,
  AdditionalServices,
} from "./index";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 500,
  },
  tab: {
    padding: "18px 24px",
    flexDirection: "row",
  },
}));

export default function TabMenu(props) {
  const classes = useStyles();
  const {
    edit,
    setFieldValue,
    toggleOptionDialogOpen,
    toggleServiceDialogOpen,
    submitCount,
    ...rest
  } = props;

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      label: "Grundinformation",
      fields: [
        "id",
        "price",
        "minimumDeliveryDaysFrom",
        "minimumDeliveryDaysTo",
        "description",
        "customerErrorMessage",
        "logo",
      ],
    },
    {
      label: "Villkor",
      fields: ["shouldAlwaysMeetRequirements", "requiresWebStock"],
    },
    { label: "Alternativ", fields: [] },
    { label: "Tilläggstjänster", fields: [] },
  ];

  const errorsInTab = (tab) => {
    const found = Object.keys(rest.errors).some(
      (r) => tab.fields.indexOf(r) >= 0
    );
    return found;
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        aria-label="simple tabs example"
        indicatorColor="primary"
        style={{ borderBottom: "1px solid #e8e8e8", background: "#fafafa" }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label + index}
            className={classes.tab}
            label={
              <Box display="flex" alignItems="center">
                {tab.label}{" "}
                {errorsInTab(tab) && submitCount > 0 ? (
                  <InfoIcon
                    fontSize="inherit"
                    style={{
                      marginLeft: 5,

                      color: "#e53935",
                      fontSize: 18,
                    }}
                  />
                ) : null}
              </Box>
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <TabPanel value={value} index={0}>
        {value === 0 ? (
          <BaseInformation
            edit={edit}
            setFieldValue={setFieldValue}
            {...rest}
          />
        ) : null}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {value === 1 ? (
          <Settings setFieldValue={setFieldValue} {...rest} />
        ) : null}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {value === 2 ? (
          <Options
            setFieldValue={setFieldValue}
            toggleOptionDialogOpen={toggleOptionDialogOpen}
            {...rest}
          />
        ) : null}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {value === 3 ? (
          <AdditionalServices
            setFieldValue={setFieldValue}
            toggleServiceDialogOpen={toggleServiceDialogOpen}
            {...rest}
          />
        ) : null}
      </TabPanel>
    </div>
  );
}
