import { Box, Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FileUploader } from "./index";
import React from "react";

export default function UploadedFile(props) {
  const { values, setFieldValue } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box
        p={2}
        style={{
          borderRadius: 4,
          border: "solid 1px lightgrey",
        }}
      >
        <Box height="290px">
          <img
            src={`data:image/svg+xml;base64,${values.logo}`}
            height="100%"
            width="100%"
          />
        </Box>
        <Box mt={2} textAlign="right">
          <FileUploader setFieldValue={setFieldValue} />
        </Box>
      </Box>
    </Box>
  );
}
