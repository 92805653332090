import React from "react";
import { useOverviewPage } from "./index";
import {
  ShippingMethodCardList,
  ConfirmationDialog,
} from "../../components/index";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function OverviewPage() {
  const {
    shippingMethods,
    setShippingMethods,
    deleteDialogMethod,
    confirmationDialogOpen,
    toggleConfirmationDialogOpen,
  } = useOverviewPage();
  const history = useHistory();

  return (
    <Box maxWidth="1200px" py={12} mx={10}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Leveranssätt</Typography>
        <Button
          variant="contained"
          color="secondary"
          style={{ color: "white" }}
          onClick={() => history.push("create")}
        >
          nytt leveranssätt
        </Button>
      </Box>
      <Box mt={2}>
        <Divider />
      </Box>
      <Box mt={5}>
        <ShippingMethodCardList
          shippingMethods={shippingMethods}
          setShippingMethods={setShippingMethods}
          toggleConfirmationDialogOpen={toggleConfirmationDialogOpen}
        />
        <ConfirmationDialog
          title="Ta bort Leveranssätt"
          contentText="Är du säker på att du vill ta bort detta leveranssätt? Det kommer att försvinna permanent."
          confirmationDialogOpen={confirmationDialogOpen}
          toggleConfirmationDialogOpen={toggleConfirmationDialogOpen}
          confirmationMethod={deleteDialogMethod}
        />
      </Box>
    </Box>
  );
}
