import * as Yup from "yup";
import { useContextApi } from "../../../context/index";

export default function useValidationSchemas(props) {
  const { shippingMethods, shippingMethod } = props;
  const { packageSettings } = useContextApi();

  console.log(shippingMethods);

  const uniqueId = (id) => {
    const existingIds = shippingMethods
      .map((o) => o.id)
      .filter((i) => i !== shippingMethod.id);

    console.log(existingIds);

    return !existingIds.includes(id);
  };

  const mainValidationSchema = Yup.object().shape({
    id: Yup.string()
      .required("Detta fältet får ej vara tomt.")
      .max(50, "Max antal tecken för id är 50.")
      .test("id is unique", "Detta id finns redan.", (val) => {
        return uniqueId(val);
      }),
    name: Yup.string()
      .required("Detta fältet får ej vara tomt.")
      .max(50, "Max antal tecken för namn är 50."),
    activationDate: Yup.date().required("Ange ett aktiveringsdatum"),
    logo: Yup.string().required("Vänligen ladda upp en logotyp."),
    price: Yup.number()
      .required("Vänligen ange ett pris.")
      .min(0, "Minsta värdet för pris är 0"),
    minimumDeliveryDaysFrom: Yup.number()
      .required("Ange minsta leveranstid i dagar")
      .max(
        Yup.ref("minimumDeliveryDaysTo"),
        "Från kan inte vara större än till"
      )
      .min(0, "Minsta värdet är 0"),
    minimumDeliveryDaysTo: Yup.number()
      .required("Ange längsta leveranstid i dagar")
      .min(
        Yup.ref("minimumDeliveryDaysFrom"),
        "Till kan inte vara mindre än från"
      ),
    description: Yup.string().max(1000, "Max antal tecken är satt till 1000."),
    customerErrorMessage: Yup.string()
      .max(1000, "Max antal tecken är satt till 1000.")
      .nullable(),
    shouldAlwaysMeetRequirements: Yup.boolean(),
    requiresWebStock: Yup.boolean(),
    //TODO : Är det värt att lägga till nedan för alla paketmått eller räcker det med min/max prop på inputs??
    minLength: Yup.number()
      .min(
        packageSettings.length.min,
        `Minsta värdet för längd är ${packageSettings.length.min}`
      )
      .max(
        packageSettings.length.max,
        `Största värdet för längd är ${packageSettings.length.max}`
      ),
    maxLength: Yup.number()
      .max(
        packageSettings.length.max,
        `Största värdet för längd är ${packageSettings.length.max}`
      )
      .min(
        packageSettings.length.min,
        `Minsta värdet för längd är ${packageSettings.length.min}`
      ),
  });

  const dialogValidationSchema = Yup.object().shape({
    id: Yup.string()
      .required("Detta fältet får ej vara tomt.")
      .max(50, "Max antal tecken för id är 50.")
      .test("id is unique", "Detta id finns redan.", (val) => {
        return uniqueId(val);
      }),
    name: Yup.string()
      .required("Detta fältet får ej vara tomt.")
      .max(50, "Max antal tecken för namn är 50."),
    activationDate: Yup.date().required("Ange ett aktiveringsdatum"),
    price: Yup.number()
      .required("Vänligen ange ett pris.")
      .min(0, "Minsta värdet för pris är 0"),
    description: Yup.string().max(1000, "Max antal tecken är satt till 1000."),
  });

  return {
    mainValidationSchema,
    dialogValidationSchema,
  };
}
