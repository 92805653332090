import React from "react";
import { EditShippingMethodForm } from "../../components/index";
import { useCreatePage } from "./index";
import { Paper, Box } from "@material-ui/core";

export default function CreatePage() {
  const { shippingMethods, createShippingMethod } = useCreatePage();

  const methodTemplate = {
    id: "",
    name: "Nytt Leveranssätt",
    price: 0,
    index: shippingMethods.data.length,
    activationDate: new Date().toISOString(),
    logo: "",
    customerErrorMessage: null,
    description: "",
    maxCircumference: 999,
    minCircumference: 0,
    maxVolume: 999,
    minVolume: 0,
    maxWeight: 999,
    minWeight: 0,
    maxWidth: 999,
    minWidth: 0,
    maxLength: 999,
    minLength: 0,
    maxVolumeWeight: 999,
    minVolumeWeight: 0,
    minimumDeliveryDaysFrom: 0,
    minimumDeliveryDaysTo: 0,
    requiresWebStock: false,
    shouldAlwaysMeetRequirements: true,
    additionalServices: [],
    options: [],
  };

  console.log(methodTemplate);

  return (
    <Box maxWidth="1500px" py={12} mx={10}>
      {shippingMethods.loading ? (
        <p>LOADING...</p>
      ) : shippingMethods.completed ? (
        <Paper elevation={3}>
          <EditShippingMethodForm
            shippingMethods={shippingMethods}
            shippingMethod={methodTemplate}
            submitMethod={createShippingMethod}
          />
        </Paper>
      ) : null}
    </Box>
  );
}
