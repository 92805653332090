import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useContextApi } from "../../../context/index";

export default function useOverviewPage() {
  const { getShippingMethodsApiTokenRedirect, getHost, showAlert } =
    useContextApi();
  const [shippingMethods, setShippingMethods] = useState({
    data: [],
    loading: false,
    completed: false,
    error: false,
  });
  const [requestId, setRequestId] = useState(0);

  const handleRequestId = () => {
    setRequestId(new Date().getUTCMilliseconds());
  };

  const sortByIndex = (array) => {
    return (
      array &&
      array.sort((a, b) => {
        var keyA = new Date(a.index),
          keyB = new Date(b.index);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      })
    );
  };

  const getShippingMethods = () => {
    setShippingMethods({ ...shippingMethods, loading: true });
    const host = getHost();
    getShippingMethodsApiTokenRedirect()
      .then((res) => {
        axios({
          method: "GET",
          url: `${host}${process.env.REACT_APP_SHIPPINGMETHODS_RELATIVE_API_PATH}/ShippingMethods`,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then((res) => {
            let sortedData = sortByIndex(res.data.result);
            setShippingMethods({
              ...shippingMethods,
              data: sortedData,
              loading: false,
              completed: true,
              error: false,
            });
          })
          .catch((error) =>
            setShippingMethods({
              ...shippingMethods,
              data: [],
              loading: false,
              completed: true,
              error: true,
            })
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteShippingMethod = (methodId) => {
    const host = getHost();
    getShippingMethodsApiTokenRedirect()
      .then((res) => {
        axios({
          method: "DELETE",
          url: `${host}${process.env.REACT_APP_SHIPPINGMETHODS_RELATIVE_API_PATH}/ShippingMethods/${methodId}`,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then((res) => {
            showAlert("Sparat!", "success");
            handleRequestId();
          })
          .catch((error) => {
            showAlert("Något gick fel, pröva igen.", "error");
            handleRequestId();
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getShippingMethods();
  }, [requestId]);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState({
    open: false,
    payload: null,
  });

  const toggleConfirmationDialogOpen = useCallback((payload) => {
    setConfirmationDialogOpen({
      open: !confirmationDialogOpen.open,
      payload: payload ?? null,
    });
  });

  const deleteDialogMethod = () => {
    const methodId =
      confirmationDialogOpen.payload !== null
        ? confirmationDialogOpen.payload.methodId
        : null;

    if (methodId !== null) {
      deleteShippingMethod(methodId);
      toggleConfirmationDialogOpen();
    } else {
      console.log("No methodId was given when dialog was toggled");
      toggleConfirmationDialogOpen();
    }
  };

  return {
    shippingMethods,
    setShippingMethods,
    deleteDialogMethod,
    confirmationDialogOpen,
    toggleConfirmationDialogOpen,
  };
}
