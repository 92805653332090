import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Context } from "./index";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export default function ContextProvider(props) {
  const { children } = props;
  const { instance, accounts, inProgress } = useMsal();

  async function getShippingMethodsApiTokenRedirect() {
    if (inProgress === "none" && accounts.length > 0) {
      var request = {
        account: accounts[0],
        scopes: ["api://4bad0fbd-97b8-481c-ba81-88a82dcba842/Service.Writer"],
      };
      return await instance.acquireTokenSilent(request).catch(async (error) => {
        console.log("silent token acquisition fails.");
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          console.log("acquiring token using redirect");
          instance.acquireTokenRedirect(request);
        } else {
          console.error(error);
        }
      });
    }
    return null;
  }

  async function getGraphApiTokenRedirect() {
    if (inProgress === "none" && accounts.length > 0) {
      var request = {
        account: accounts[0],
        scopes: ["User.Read"],
      };
      return await instance.acquireTokenSilent(request).catch(async (error) => {
        console.log("silent token acquisition fails.");
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          console.log("acquiring token using redirect");
          instance.acquireTokenRedirect(request);
        } else {
          console.error(error);
        }
      });
    }
    return null;
  }

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const showAlert = (message, severity) => {
    setSnackbar({
      open: true,
      message: message,
      severity: severity,
    });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const getHost = () => {
    var currentLocation = window.location.hostname.split(".")[0];
    var localAPi = process.env.REACT_APP_SHIPPINGMETHODS_LOCAL_API;

    if (localAPi) {
      return localAPi;
    }

    switch (currentLocation) {
      case "qa":
        return "https://www.mio.qa";
        break;
      case "mio":
        return "https://www.mio.se";
        break;
      default:
        return "https://www.miote.st";
        break;
    }
  };

  const todayMidnightISOString = new Date(
    new Date().setHours(0, 0, 0, 0)
  ).toISOString();

  const formatDate = (date, format) => {
    var d = new Date(date);
    var curr_year = d.getFullYear();
    var curr_month = ("0" + (d.getMonth() + 1)).slice(-2); //Months are zero based
    var curr_date = ("0" + d.getDate()).slice(-2);
    var curr_hours = ("0" + d.getHours()).slice(-2);
    var curr_minutes = ("0" + d.getMinutes()).slice(-2);

    if (format === "YYYY-MM-DDTHH:MM") {
      return (
        curr_year +
        "-" +
        curr_month +
        "-" +
        curr_date +
        "T" +
        curr_hours +
        ":" +
        curr_minutes
      );
    }
  };

  const packageSettings = {
    length: { min: 0, max: 1000, step: 25 },
    width: { min: 0, max: 1000, step: 25 },
    circumference: { min: 0, max: 1000, step: 25 },
    weight: { min: 0, max: 1000, step: 25 },
    volume: { min: 0, max: 1000, step: 25 },
    volumeWeight: { min: 0, max: 1000, step: 25 },
  };

  const methods = {
    showAlert,
    getShippingMethodsApiTokenRedirect,
    getGraphApiTokenRedirect,
    accounts,
    todayMidnightISOString,
    formatDate,
    packageSettings,
    getHost,
  };
  return (
    <Context.Provider value={methods}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        open={snackbar.open}
        autoHideDuration={2000}
      >
        <Alert elevation={7} variant="filled" severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Context.Provider>
  );
}
