import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Card, Typography } from "@material-ui/core";
import { useStyles } from "./index";
import { StatusChip } from "../index";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { useContextApi } from "../../context/index";

export default function ShippingMethodCard(props) {
  const { methodId, method, toggleConfirmationDialogOpen } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: methodId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const classes = useStyles();
  const { formatDate } = useContextApi();

  return (
    <div>
      <Card className={classes.cardWrapper} ref={setNodeRef} style={style}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <Box width="32px" height="24px" mr={1}>
                  <img
                    height="100%"
                    width="100%"
                    src={`data:image/svg+xml;base64,${method.logo}`}
                  />
                </Box>
                <Typography>{method.name}</Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" mb={0.2}>
                  <Box mr={1}>
                    <Typography color="textSecondary">
                      Visningsordning:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>{method.index + 1}</Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <Typography color="textSecondary">
                      Aktiveringsdatum:
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {formatDate(
                        method.activationDate,
                        "YYYY-MM-DDTHH:MM"
                      ).replace("T", " ")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography>{method.price} kr</Typography>
            </Box>
            <Box mb={0.3}>
              <StatusChip activationDate={method.activationDate} />
            </Box>
          </Box>
          <Box
            ml={2.5}
            pl={2.5}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            borderLeft={0.1}
            borderColor="grey.300"
          >
            <div {...attributes} {...listeners}>
              <DragIndicatorIcon
                style={{
                  cursor: "grab",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              />
            </div>
            <div>
              <DeleteOutlineIcon
                style={{ color: "rgba(0, 0, 0, 0.54)", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleConfirmationDialogOpen({ methodId: methodId });
                }}
              />
            </div>
            <div>
              <EditIcon
                style={{
                  color: "rgba(0, 0, 0, 0.54)",
                  fontSize: 21,
                  cursor: "pointer",
                }}
              />
            </div>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
