import React from "react";
import { Switch, Route } from "react-router-dom";
import { OverviewPage, EditPage, CreatePage } from "../pages/index";
import { Layout } from "../components/index";

export default function Router(props) {
  return (
    <Layout>
      <Switch>
        <Route path="/edit/:id" component={EditPage} />
        <Route path="/create" component={CreatePage} />
        <Route path="/" component={OverviewPage} />
      </Switch>
    </Layout>
  );
}
