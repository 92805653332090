import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./app/index";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "d03af03a-fe3a-4184-a81e-07aed524e4a2",
    authority:
      "https://login.microsoftonline.com/027d910f-d438-4b74-b99e-3d163b988963",
    redirectUri: window.location.origin,
  },
};

const pca = new PublicClientApplication(msalConfig);

const AppProvider = () => (
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>
);

ReactDOM.render(<AppProvider />, document.getElementById("root"));
