import React from "react";
import { EditShippingMethodForm } from "../../components/index";
import { useEditPage } from "./index";
import { Paper, Box } from "@material-ui/core";

export default function EditPage() {
  const { shippingMethods, shippingMethod, UpdateShippingMethod } =
    useEditPage();
  return (
    <Box maxWidth="1500px" py={12} mx={10}>
      {shippingMethods.loading ? (
        <p>LOADING...</p>
      ) : shippingMethods.completed ? (
        !!shippingMethod ? (
          <Paper elevation={3}>
            <EditShippingMethodForm
              edit
              shippingMethods={shippingMethods}
              shippingMethod={shippingMethod}
              submitMethod={UpdateShippingMethod}
            />
          </Paper>
        ) : (
          <div style={{ padding: 60 }}>404 page</div>
        )
      ) : null}
    </Box>
  );
}
