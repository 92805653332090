import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useHistory } from "react-router-dom";
import { ShippingMethodCard } from "../index";
import { Grid } from "@material-ui/core";

export default function ShippingMethodCardList(props) {
  const { shippingMethods, setShippingMethods, toggleConfirmationDialogOpen } =
    props;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const history = useHistory();

  const [isSorting, setIsSorting] = useState(false);

  function handleDragStart() {
    setIsSorting(true);
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = shippingMethods.data.map((m) => m.id).indexOf(active.id);
      const newIndex = shippingMethods.data.map((m) => m.id).indexOf(over.id);

      setShippingMethods({
        ...shippingMethods,
        data: arrayMove(shippingMethods.data, oldIndex, newIndex).map(
          (m, i) => {
            return { ...m, index: i };
          }
        ),
      });

      setTimeout(() => setIsSorting(false), 100);
    }
  };

  console.log(isSorting);

  return (
    <Grid container spacing={4}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={shippingMethods.data}
          strategy={rectSortingStrategy}
        >
          {shippingMethods.data.map((method) => (
            <Grid
              item
              xs={12}
              lg={6}
              key={method.id}
              onClick={
                isSorting ? undefined : () => history.push(`edit/${method.id}`)
              }
            >
              <ShippingMethodCard
                methodId={method.id}
                method={method}
                toggleConfirmationDialogOpen={toggleConfirmationDialogOpen}
              />
            </Grid>
          ))}
        </SortableContext>
      </DndContext>
    </Grid>
  );
}
