import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useMsal } from "@azure/msal-react";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    minWidth: 250,
    height: "100%",
  },
  list: {
    paddingBottom: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawer: {
    left: 64,
  },
  account: {
    height: 60,
    padding: 12,
    flexWrap: "wrap",
  },
  logoutButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginLeft: 10,
  },
  drawerTitle: {
    paddingTop: 21,
    paddingBottom: 40,
  },
}));

export default function NavDrawer(props) {
  const { drawerOpen, toggleDrawer, accounts } = props;
  const classes = useStyles();
  const history = useHistory();
  const { instance } = useMsal();

  const handleLogout = () => {
    const currentAccount = instance.getAccountByHomeId(
      accounts[0].homeAccountId
    );

    instance.logout({
      account: currentAccount,
      postLogoutRedirectUri: window.location.origin,
      authority:
        "https://login.microsoftonline.com/18ac49d1-d4dc-41a0-a707-ee1acbf0e57b",
    });
  };

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"left"}
          open={drawerOpen}
          onClose={toggleDrawer()}
          classes={{
            paper: classes.drawer,
          }}
        >
          <div
            className={classes.listWrapper}
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}
          >
            <List className={classes.list}>
              <div>
                <ListItem className={classes.drawerTitle}>
                  <Typography variant="h6">Mio Leveranssätt</Typography>
                </ListItem>
                <ListItem
                  button
                  onClick={() => history.push("/")}
                  alignItems="center"
                >
                  <HomeIcon style={{ color: "lightgrey", marginRight: 10 }} />
                  <Typography>Hem</Typography>
                </ListItem>
              </div>
              <div>
                <ListItem button className={classes.account}>
                  <ListItemText primary={accounts[0].name} />
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.logoutButton}
                    onClick={handleLogout}
                  >
                    logga ut
                  </Button>
                </ListItem>
              </div>
            </List>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
