import { createMuiTheme } from "@material-ui/core";
import { blue, red, green, orange, white } from "@material-ui/core/colors";

const theme = new createMuiTheme({
  palette: {
    primary: {
      main: blue[600],
    },
    secondary: {
      main: green[400],
      secondary: green[300],
    },
    error: {
      main: red[600],
    },
    warning: {
      main: orange[500],
    },
  },
});

export default theme;
