import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useContextApi } from "../../../context/index";
import { useParams } from "react-router-dom";

export default function useEditPage() {
  const [shippingMethods, setShippingMethods] = useState({
    data: [],
    loading: false,
    completed: false,
    error: false,
  });

  const { getShippingMethodsApiTokenRedirect, showAlert, getHost } =
    useContextApi();

  const [requestId, setRequestId] = useState(0);

  const handleRequestId = () => {
    setRequestId(new Date().getUTCMilliseconds());
  };

  const getShippingMethods = () => {
    setShippingMethods({ ...shippingMethods, loading: true });
    const host = getHost();
    getShippingMethodsApiTokenRedirect()
      .then((res) => {
        axios({
          method: "GET",
          url: `${host}${process.env.REACT_APP_SHIPPINGMETHODS_RELATIVE_API_PATH}/ShippingMethods`,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then((res) => {
            setShippingMethods({
              ...shippingMethods,
              data: res.data.result,
              loading: false,
              completed: true,
              error: false,
            });
          })
          .catch((error) =>
            setShippingMethods({
              ...shippingMethods,
              data: [],
              loading: false,
              completed: true,
              error: true,
            })
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdateShippingMethod = (data) => {
    const host = getHost();
    getShippingMethodsApiTokenRedirect()
      .then((res) => {
        axios({
          method: "PUT",
          url: `${host}${process.env.REACT_APP_SHIPPINGMETHODS_RELATIVE_API_PATH}/ShippingMethods/${data.id}`,
          data: data,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then((res) => {
            showAlert("Sparat!", "success");
            handleRequestId();
          })
          .catch((error) => {
            showAlert("Något gick fel, pröva igen.", "error");
            handleRequestId();
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getShippingMethods();
  }, [requestId]);

  const { id } = useParams();

  let methodObject =
    shippingMethods.data && shippingMethods.data.find((x) => x.id === id);

  const shippingMethod = !!methodObject
    ? JSON.parse(JSON.stringify(methodObject))
    : methodObject;

  return {
    shippingMethods,
    shippingMethod,
    UpdateShippingMethod,
  };
}
