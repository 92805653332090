import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useStyles } from "./index";
import { useContextApi } from "../../../context/index";
import { Button, Paper, Box } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const DragAndDrop = (props) => {
  const { getInputProps, getRootProps, verticalPadding } = props;
  const classes = useStyles();

  return (
    <div {...getRootProps()} className={classes.inputBaseStyle}>
      <input {...getInputProps()} />
      <Paper elevation={0} className={classes.dragAndDrop}>
        <Box
          component="div"
          py={verticalPadding}
          width="100%"
          height="100%"
          align="center"
        >
          <FolderIcon color="primary" fontSize="large" />
          <p>Släpp eller klicka här för att välja logotyp</p>
        </Box>
      </Paper>
    </div>
  );
};

const UploadButton = (props) => {
  const { getInputProps, getRootProps } = props;

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ color: "white", textTransform: "none" }}
        startIcon={<CloudUploadIcon />}
        {...getRootProps()}
      >
        Ladda upp ny fil
        <input {...getInputProps()} />
      </Button>
    </div>
  );
};

export default function FileUploader(props) {
  const { dragAndDrop, setFieldValue, verticalPadding } = props;
  const { showAlert } = useContextApi();

  const onDrop = useCallback((acceptedFile) => {
    acceptedFile.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // sätt file till base64 string
        setFieldValue("logo", reader.result.split(",")[1]);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const onDropRejected = useCallback((thang) => {
    showAlert(thang[0].errors[0].message, "error");
  });

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDropRejected,
    onDrop,
    maxFiles: 1,
    accept: "image/svg+xml",
  });

  return (
    <>
      {dragAndDrop ? (
        <Box height={"100%"} width={"100%"}>
          <DragAndDrop
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            verticalPadding={verticalPadding}
          />
        </Box>
      ) : (
        <UploadButton
          getInputProps={getInputProps}
          getRootProps={getRootProps}
        />
      )}
    </>
  );
}
