import React from "react";
import { Card, Typography, Box } from "@material-ui/core";
import { useStyles } from "./index";
import { Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { StatusChip } from "../../index";
import { useContextApi } from "../../../context/index";

export default function ServiceCard(props) {
  const { service, index } = props;
  const classes = useStyles();
  const { formatDate } = useContextApi();

  return (
    <Draggable draggableId={service.id} index={index}>
      {(provided) => (
        <Card
          className={classes.card}
          {...provided.draggableProps}
          innerRef={provided.innerRef}
        >
          <Box display="flex">
            <Box flexGrow={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography>{service.name}</Typography>
                </Box>
                <Box>
                  <Typography>{service.price} kr</Typography>
                </Box>
              </Box>
              <Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box>
                    <Typography>
                      <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                        Visningsordning:
                      </span>{" "}
                      {service.index + 1}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                        Aktiveringsdatum:
                      </span>{" "}
                      {formatDate(service.activationDate, "YYYY-MM-DDTHH:MM")}
                    </Typography>
                  </Box>
                  <Box width="83px" textAlign="right">
                    <StatusChip activationDate={service.activationDate} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box {...provided.dragHandleProps} ml={2} mt={0.9}>
              <DragIndicatorIcon
                style={{ cursor: "grab", color: "rgba(0, 0, 0, 0.54)" }}
              />
            </Box>
          </Box>
        </Card>
      )}
    </Draggable>
  );
}
