import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { Box, Grid, Input, Typography, FormControl } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
    marginLeft: 20,
    marginRight: 15,
  },
  input: {
    width: 42,
  },
  centerInputText: {
    textAlign: "center",
  },
}));

const AirbnbSlider = withStyles({
  root: {
    color: "#3a8589",
    height: 3,
    padding: "13px 0",
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

export default function RangeSlider(props) {
  const {
    minValue,
    maxValue,
    stepValue,
    fieldset,
    setFieldValue,
    label,
    values,
    unit,
  } = props;
  const classes = useStyles();

  const [rangeValue, setRangeValue] = useState([
    values[fieldset[0]],
    values[fieldset[1]],
  ]);

  useEffect(() => {
    setRangeValue([values[fieldset[0]], values[fieldset[1]]]);
  }, [values[fieldset[0]], values[fieldset[1]]]);

  const handleSliderChange = (event, newValue) => {
    setRangeValue(newValue);
  };

  const handleInputChange = (event, index) => {
    setRangeValue(
      rangeValue.map((s, i) => (i === index ? parseInt(event.target.value) : s))
    );
  };

  const setFormikState = () => {
    setFieldValue(fieldset[0], rangeValue[0]);
    setFieldValue(fieldset[1], rangeValue[1]);
  };

  const handleBlur = (event) => {
    if (rangeValue[0] < minValue) {
      setRangeValue(rangeValue.map((v, i) => (i === 0 ? minValue : v)));
    }

    setFormikState();
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <div>
          <Typography display="inline" id="input-slider" gutterBottom>
            {label}
          </Typography>

          <Typography display="inline" color="textSecondary">
            &nbsp;&nbsp;({unit})&nbsp;
          </Typography>
        </div>
        <Box display="flex">
          <Box mr={2}>
            <Input
              disabled={values.shouldAlwaysMeetRequirements}
              className={classes.input}
              value={rangeValue[0]}
              margin="dense"
              onChange={(e) => handleInputChange(e, 0)}
              onBlur={handleBlur}
              inputProps={{
                min: minValue,
                max: maxValue,
                type: "number",
                "aria-labelledby": "input-slider",
                className: classes.centerInputText,
              }}
            />
          </Box>
          <Typography> - </Typography>
          <Box ml={2}>
            <Input
              disabled={values.shouldAlwaysMeetRequirements}
              className={classes.input}
              value={rangeValue[1]}
              margin="dense"
              onChange={(e) => handleInputChange(e, 1)}
              onBlur={handleBlur}
              inputProps={{
                min: minValue,
                max: maxValue,
                type: "number",
                "aria-labelledby": "input-slider",
                className: classes.centerInputText,
              }}
            />
          </Box>
        </Box>
      </Box>
      <AirbnbSlider
        disabled={values.shouldAlwaysMeetRequirements}
        value={rangeValue}
        onChange={handleSliderChange}
        onChangeCommitted={setFormikState}
        ThumbComponent={AirbnbThumbComponent}
        getAriaLabel={(index) =>
          index === 0 ? "Minimum price" : "Maximum price"
        }
        min={minValue}
        max={maxValue}
        step={stepValue}
      />
    </Box>
  );
}
