import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navbarWrapper: {
    height: "100%",
    minWidth: 64,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1301,
    backgroundColor: "#1e88e5",
    overflowX: "hidden",
    color: "#f4f4f4",
    display: "flex",
    flexDirection: "row",
  },
  navbarIcons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  menuButton: {
    width: 64,
    height: 60,
    padding: 12,
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    height: 35,
    width: 35,
  },
}));

export default useStyles;
