import { Box, Typography, Switch } from "@material-ui/core";
import React from "react";
import { RangeSlider } from "../index";

export default function Settings(props) {
  const { setFieldValue, ...rest } = props;

  return (
    <>
      <Box mt={-5} mb={3} px={3}>
        <Box
          mt={10}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box pl={1}>
            <Typography
              variant="h6"
              display="inline"
              style={{ marginRight: 20 }}
            >
              Visa alltid i kassan
            </Typography>
            <Switch
              checked={rest.values.shouldAlwaysMeetRequirements}
              onChange={rest.handleChange}
              name="shouldAlwaysMeetRequirements"
              color="primary"
            />
            <Typography variant="body2" color="textSecondary" display="block">
              Här kan du styra så att leveranssättet alltid möter
              <br />
              kriterierna för att visas i kassan.
            </Typography>
          </Box>
          <Box flexGrow={0.75} pl={1}>
            <Typography
              variant="h6"
              display="inline"
              style={{ marginRight: 20 }}
            >
              Kräver försörjning via webblager
            </Typography>
            <Switch
              checked={rest.values.requiresWebStock}
              onChange={rest.handleChange}
              name="requiresWebStock"
              color="primary"
            />
            <Typography variant="body2" color="textSecondary" display="block">
              För att leveransalternativet ska vara möjligt måste hela
              <br />
              kundvagnen kunna försörjas via webblagret (TB1)
            </Typography>
          </Box>
        </Box>

        <Box mt={7.5} pl={1} mb={5} width="100%">
          <Typography variant="h6" display="inline" style={{ marginRight: 20 }}>
            Mått på paket
          </Typography>
          <Typography variant="body2" color="textSecondary" display="block">
            Lägsta och högsta tillåtna mått på paket för
            <br />
            leveranssättet
          </Typography>
        </Box>

        <Box
          mt={7.5}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box>
            <Box mb={2}>
              <RangeSlider
                disabled={true}
                minValue={0}
                maxValue={1000}
                stepValue={25}
                unit="cm"
                setFieldValue={setFieldValue}
                fieldset={["minLength", "maxLength"]}
                label="Längd"
                {...rest}
              />
            </Box>
            <Box mb={2}>
              <RangeSlider
                minValue={0}
                maxValue={1000}
                stepValue={25}
                unit="cm"
                setFieldValue={setFieldValue}
                fieldset={["minWidth", "maxWidth"]}
                label="Bredd"
                {...rest}
              />
            </Box>
            <Box mb={2}>
              <RangeSlider
                minValue={0}
                maxValue={1000}
                stepValue={25}
                unit="cm"
                setFieldValue={setFieldValue}
                fieldset={["minCircumference", "maxCircumference"]}
                label="Omkrets"
                {...rest}
              />
            </Box>
          </Box>
          <Box flexGrow={0.8}>
            <Box mb={2}>
              <RangeSlider
                minValue={0}
                maxValue={1000}
                stepValue={25}
                unit="kg"
                setFieldValue={setFieldValue}
                fieldset={["minWeight", "maxWeight"]}
                label="Vikt"
                {...rest}
              />
            </Box>
            <Box mb={2}>
              <RangeSlider
                minValue={0}
                maxValue={1000}
                stepValue={25}
                unit="m3"
                setFieldValue={setFieldValue}
                fieldset={["minVolume", "maxVolume"]}
                label="Volym"
                {...rest}
              />
            </Box>
            <Box mb={2}>
              <RangeSlider
                minValue={0}
                maxValue={1000}
                stepValue={25}
                unit="kg/m3"
                setFieldValue={setFieldValue}
                fieldset={["minVolumeWeight", "maxVolumeWeight"]}
                label="Volymvikt"
                {...rest}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
