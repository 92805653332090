import { Box, InputAdornment } from "@material-ui/core";
import React from "react";
import { Input, UploadedFile, DateTimePicker, FileUploader } from "../index";
import { ErrorMessage } from "formik";

export default function BaseInformation(props) {
  const { edit, fileRejections, setFieldValue, ...rest } = props;

  const parseNewId = (id) => {
    const badCharacters = /[., :;\n\t]/g;

    return id.toUpperCase().replace(badCharacters, "");
  };

  return (
    <>
      <ErrorMessage name="logo">
        {(msg) => (
          <Box
            maxWidth="500px"
            p={1.5}
            ml={5}
            style={{ color: "red", borderRadius: 8, border: "1px solid red" }}
          >
            {msg}
          </Box>
        )}
      </ErrorMessage>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        mt={-3}
        mb={5}
        px={5}
      >
        <Box minWidth="400px" width="30%" mt={6}>
          <Input
            {...rest}
            disabled={edit}
            field="id"
            label="Id"
            fullWidth
            handleBlur={(e) => setFieldValue("id", parseNewId(e.target.value))}
          />
          <Input {...rest} field="name" label="Namn" fullWidth />
          <DateTimePicker
            {...rest}
            setFieldValue={setFieldValue}
            field="activationDate"
            label="Aktiveringsdatum"
            fullWidth
          />
          <Input
            {...rest}
            field="price"
            label="Pris"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment>Kr </InputAdornment>,
            }}
          />
          <Box display="flex" justifyContent="space-between">
            <Box mr={3}>
              <Input
                {...rest}
                field="minimumDeliveryDaysFrom"
                label="Leveranstid från"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment>Dagar </InputAdornment>,
                }}
              />
            </Box>
            <Input
              {...rest}
              field="minimumDeliveryDaysTo"
              label="Leveranstid till"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment>Dagar </InputAdornment>,
              }}
            />
          </Box>
        </Box>
        <Box
          minWidth="400px"
          width="30%"
          mt={6}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Input
            {...rest}
            field="description"
            label="Beskrivning"
            rows={7}
            multiline
            fullWidth
          />
          <Input
            {...rest}
            field="customerErrorMessage"
            label="Felmeddelande"
            rows={7}
            multiline
            fullWidth
          />
        </Box>
        <Box minWidth="400px" width="30%" mt={8}>
          {!rest.values.logo ? (
            <FileUploader
              setFieldValue={setFieldValue}
              dragAndDrop
              verticalPadding={17.3}
            />
          ) : (
            <UploadedFile values={rest.values} setFieldValue={setFieldValue} />
          )}
        </Box>
      </Box>
    </>
  );
}
