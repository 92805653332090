import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { ContextProvider } from "../context/index";
import { Router } from "./index";
import { Theme } from "../styles/index";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
  useMsal,
  useAccount,
} from "@azure/msal-react";

export default function App() {
  const { login, result, error } = useMsalAuthentication("redirect", {
    scopes: ["User.Read"],
  });

  return (
    <>
      <AuthenticatedTemplate>
        <BrowserRouter>
          <ThemeProvider theme={Theme}>
            <ContextProvider>
              <Router />
            </ContextProvider>
          </ThemeProvider>
        </BrowserRouter>
      </AuthenticatedTemplate>
    </>
  );
}
