import React from "react";
import {
  Dialog,
  InputAdornment,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Input, DateTimePicker } from "../index";
import { useContextApi } from "../../../context/index";

export default function ServiceDialog(props) {
  const {
    serviceDialogOpen,
    toggleServiceDialogOpen,
    parentValues,
    setParentFieldValue,
    edit,
    validationSchema,
  } = props;
  const { formatDate } = useContextApi();

  const emptyService = {
    id: "",
    name: "",
    price: 0,
    description: "",
    activationDate: formatDate(new Date(), "YYYY-MM-DDTHH:MM"),
  };

  const service =
    parentValues.additionalServices.find(
      (service) => service.id === serviceDialogOpen.id
    ) ?? emptyService;

  //TODO : VID VALIDERING ANVÄND DENNA FÖR ATT KOLLA OM ID REDAN FINNS OCH FLYTTA UT TILL USE___
  const checkIdAvailability = (id) => {
    const existingIds = parentValues.additionalServices.map((o) => o.id);
    return existingIds.includes(id);
  };

  const parseNewId = (id) => {
    const badCharacters = /[., :;\n\t]/g;

    return id.toUpperCase().replace(badCharacters, "");
  };

  return (
    service && (
      <Dialog
        maxWidth="sm"
        fullWidth
        style={{ marginLeft: 64 }}
        open={serviceDialogOpen.open}
        onClose={() => toggleServiceDialogOpen()}
      >
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={{
            ...service,
          }}
          onSubmit={(values, { setSubmitting }) => {
            edit
              ? setParentFieldValue(
                  "additionalServices",
                  parentValues.additionalServices.map((service) =>
                    service.id === serviceDialogOpen.id
                      ? { ...values }
                      : service
                  )
                )
              : setParentFieldValue("additionalServices", [
                  ...parentValues.additionalServices,
                  {
                    ...values,
                    index: parentValues.additionalServices.length,
                  },
                ]);
            toggleServiceDialogOpen();
          }}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Box m={3}>
                <Box mb={7}>
                  <Typography variant="h6" gutterBottom>
                    {service.name ?? "Ny Tilläggstjänst"}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Fyll i fälten och klicka på{" "}
                    {edit ? <strong>Ändra</strong> : <strong>Skapa</strong>}{" "}
                    {edit
                      ? "för att applicera ändringarna,"
                      : "för att lägga till tilläggstjänsten,"}{" "}
                    Glöm inte att spara formuläret efter att dialog rutan
                    stängs.
                  </Typography>
                </Box>
                <Input
                  handleChange={handleChange}
                  handleBlur={(e) =>
                    setFieldValue("id", parseNewId(e.target.value))
                  }
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="id"
                  label="id"
                  fullWidth
                />
                <Input
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="name"
                  label="Namn"
                  fullWidth
                />
                <DateTimePicker
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="activationDate"
                  label="Aktiveringsdatum"
                  fullWidth
                />
                <Input
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="price"
                  label="Pris"
                  type="number"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment>Kr </InputAdornment>,
                  }}
                />
                <Input
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="description"
                  label="Beskrivning"
                  rows={7}
                  multiline
                  fullWidth
                />
                <Box mt={1} textAlign="right">
                  <Button
                    onClick={() => toggleServiceDialogOpen()}
                    color="primary"
                  >
                    Avbryt
                  </Button>
                  <Button type="submit" disabled={isSubmitting} color="primary">
                    {edit ? "Ändra" : "Skapa"}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  );
}
