import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  /* Fileupload  styles*/
  dragAndDrop: {
    borderRadius: 10,
    border: "3px dashed lightgrey",
  },
  inputBaseStyle: {
    outline: "none",
    transition: "border .24s ease-in-out",
  },

  /* Dialog styles */
  dragAndDropZone: {
    paddingBottom: 35,
  },

  /* Form Styles */

  inputfieldsWrapper: {
    paddingBottom: 25,
  },

  FileinfoList: {
    backgroundColor: "#f1f1f1",
    marginBottom: 25,
  },

  fileRejectionMessageBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "bottom",
    "& p": {
      paddingLeft: 3,
    },
  },

  card: {
    padding: 20,
    marginBottom: 20,
    width: "100%",
    cursor: "pointer",
  },
}));

export default useStyles;
