import React from "react";
import { Card, Typography, Box } from "@material-ui/core";
import { useStyles } from "./index";
import { StatusChip } from "../../index";
import { Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { useContextApi } from "../../../context/index";

export default function OptionCard(props) {
  const { option, index } = props;
  const classes = useStyles();
  const { formatDate } = useContextApi();

  return (
    <Draggable draggableId={option.id} index={index}>
      {(provided) => (
        <Card
          className={classes.card}
          {...provided.draggableProps}
          innerRef={provided.innerRef}
        >
          <Box display="flex">
            <Box flexGrow={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <img
                    height="40px"
                    width="40px"
                    src={`data:image/svg+xml;base64,${option.logo}`}
                  />
                  &nbsp;&nbsp;
                  <Typography>{option.name}</Typography>
                </Box>
                <Box>
                  <Typography>{option.price} kr</Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  mt={2}
                >
                  <Box display="flex" alignItems="center">
                    <Typography color="textSecondary">
                      Visningsordning:
                    </Typography>
                    <Typography>{option.index + 1}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography color="textSecondary">
                      Aktiveringsdatum:
                    </Typography>
                    <Typography>
                      {formatDate(option.activationDate, "YYYY-MM-DDTHH:MM")}
                    </Typography>
                  </Box>
                  <Box width="83px" textAlign="right">
                    <StatusChip activationDate={option.activationDate} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box {...provided.dragHandleProps} ml={2} mt={0.9}>
              <DragIndicatorIcon
                style={{ cursor: "grab", color: "rgba(0, 0, 0, 0.54)" }}
              />
            </Box>
          </Box>
        </Card>
      )}
    </Draggable>
  );
}
