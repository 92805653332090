import { useState, useCallback } from "react";

export default function useShippingMethodEditForm() {
  const [optionDialogOpen, setOptionDialogOpen] = useState({
    open: false,
    id: null,
  });

  const toggleOptionDialogOpen = useCallback((optionId) => {
    setOptionDialogOpen({
      open: !optionDialogOpen.open,
      id: optionId ?? null,
    });
  });

  const [serviceDialogOpen, setServiceDialogOpen] = useState({
    open: false,
    id: null,
  });

  const toggleServiceDialogOpen = useCallback((optionId) => {
    setServiceDialogOpen({
      open: !serviceDialogOpen.open,
      id: optionId ?? null,
    });
  });

  return {
    optionDialogOpen,
    toggleOptionDialogOpen,
    serviceDialogOpen,
    toggleServiceDialogOpen,
  };
}
