import { TextField } from "@material-ui/core";
import React from "react";

export default function Input(props) {
  const {
    disabled,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    field,
    label,
    ...rest
  } = props;

  return (
    <div>
      <TextField
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        margin="normal"
        name={field}
        value={values[field]}
        label={label}
        helperText={errors[field] && touched[field] && errors[field]}
        error={!!errors[field] && !!touched[field] && !!errors[field]}
        {...rest}
      />
    </div>
  );
}
