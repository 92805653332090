import React from "react";
import TextField from "@material-ui/core/TextField";
import { useContextApi } from "../../../context/index";

export default function DateTimePicker(props) {
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    field,
    label,
    setFieldValue,
    ...rest
  } = props;
  const { formatDate } = useContextApi();

  const handleDateChange = (value) => {
    setFieldValue("activationDate", formatDate(value, "YYYY-MM-DDTHH:MM"));
  };

  const minDateTime = formatDate(new Date(), "YYYY-MM-DDTHH:MM");

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      id="datetime-local"
      type="datetime-local"
      margin="normal"
      name={field}
      label={label}
      onChange={(e) => handleDateChange(e.target.value)}
      onBlur={handleBlur}
      value={values[field]}
      helperText={errors[field] && touched[field] && errors[field]}
      error={!!errors[field] && !!touched[field] && !!errors[field]}
      {...rest}
    />
  );
}
