import React, { useEffect } from "react";
import { useStyles } from "./index";
import { Navbar } from "../index";

export default function Layout(props) {
  const { title, children } = props;
  const classes = useStyles();

  useEffect(() => {
    document.title = `Leveranssätt | ${title ? title : "Hem"}`;
  });

  return (
    <div className={classes.root}>
      <Navbar />
      <div className={classes.main}>{children}</div>
    </div>
  );
}
