import { Box, Button, Typography, IconButton } from "@material-ui/core";
import React from "react";
import { useStyles } from "./index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

export default function FormControls(props) {
  const { dirty, name, handleReset, edit } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2.5}
        className={classes.formControlsWrapper}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => history.push("/")}
            style={{ margin: "0px 10px" }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">{name}</Typography>
        </Box>
        <Box display="flex">
          {dirty ? (
            !!edit ? (
              <Box>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    handleReset();
                  }}
                  style={{ color: "red", borderColor: "red" }}
                >
                  Kasta ändringar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{ color: "white", marginLeft: 15, marginRight: 22 }}
                >
                  Spara
                </Button>
              </Box>
            ) : (
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{ color: "white", marginLeft: 15, marginRight: 22 }}
                >
                  Skapa
                </Button>
              </Box>
            )
          ) : null}
        </Box>
      </Box>
    </>
  );
}
