import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    borderRadius: 0,
    padding: "30px 20px 30px 30px",
  },
}));

export default useStyles;
