import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmationDialog(props) {
  const {
    title,
    contentText,
    confirmationDialogOpen,
    toggleConfirmationDialogOpen,
    confirmationMethod,
  } = props;

  return (
    <div>
      <Dialog
        open={confirmationDialogOpen.open}
        onClose={toggleConfirmationDialogOpen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleConfirmationDialogOpen} color="primary">
            Avbryt
          </Button>
          <Button onClick={confirmationMethod} color="primary">
            Fortsätt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
