import React from "react";
import {
  Dialog,
  InputAdornment,
  Box,
  Typography,
  Paper,
  Divider,
  Button,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Input, DateTimePicker, FileUploader } from "../index";
import { useContextApi } from "../../../context/index";

export default function OptionDialog(props) {
  const {
    optionDialogOpen,
    toggleOptionDialogOpen,
    parentValues,
    setParentFieldValue,
    edit,
    validationSchema,
  } = props;
  const { formatDate } = useContextApi();

  const emptyOption = {
    name: null,
    price: null,
    description: null,
    logo: null,
    activationDate: formatDate(new Date(), "YYYY-MM-DDTHH:MM"),
  };

  const option =
    parentValues.options.find((opt) => opt.id === optionDialogOpen.id) ??
    emptyOption;

  //TODO : VID VALIDERING ANVÄND DENNA FÖR ATT KOLLA OM ID REDAN FINNS
  const checkIdAvailability = (id) => {
    const existingIds = parentValues.options.map((o) => o.id);
    return existingIds.includes(id);
  };

  const parseNewId = (id) => {
    const badCharacters = /[., :;\n\t]/g;

    return id.toUpperCase().replace(badCharacters, "");
  };

  return (
    option && (
      <Dialog
        maxWidth="sm"
        fullWidth
        style={{ marginLeft: 64 }}
        open={optionDialogOpen.open}
        onClose={() => toggleOptionDialogOpen()}
      >
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={{
            ...option,
          }}
          onSubmit={(values, { setSubmitting }) => {
            edit
              ? setParentFieldValue(
                  "options",
                  parentValues.options.map((opt) =>
                    opt.id === optionDialogOpen.id ? { ...values } : opt
                  )
                )
              : setParentFieldValue("options", [
                  ...parentValues.options,
                  {
                    ...values,
                    index: parentValues.options.length,
                  },
                ]);
            toggleOptionDialogOpen();
          }}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Box m={3}>
                <Box mb={7}>
                  <Typography variant="h6" gutterBottom>
                    {option.name ?? "Nytt Leveransalternativ"}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Fyll i fälten och klicka på{" "}
                    {edit ? <strong>Ändra</strong> : <strong>Skapa</strong>}{" "}
                    {edit
                      ? "för att applicera ändringarna,"
                      : "för att lägga till Leveransalternativet,"}{" "}
                    Glöm inte att spara formuläret efter att dialog rutan
                    stängs.
                  </Typography>
                </Box>
                <Box
                  mb={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {values.logo ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box height="40px" width="100%" maxWidth="100px">
                        <img
                          style={{ objectFit: "contain" }}
                          width="100%"
                          height="100%"
                          src={`data:image/svg+xml;base64,${values.logo}`}
                        />
                      </Box>
                    </Box>
                  ) : null}
                  <FileUploader
                    setFieldValue={setFieldValue}
                    dragAndDrop={!values.logo}
                    verticalPadding={5}
                  />
                </Box>
                <Box mb={4} hidden={!values.logo}>
                  <Divider />
                </Box>
                <Input
                  handleChange={handleChange}
                  handleBlur={(e) =>
                    setFieldValue("id", parseNewId(e.target.value))
                  }
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="id"
                  label="id"
                  fullWidth
                />
                <Input
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="name"
                  label="Namn"
                  fullWidth
                />
                <DateTimePicker
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="activationDate"
                  label="Aktiveringsdatum"
                  fullWidth
                />
                <Input
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="price"
                  label="Pris"
                  type="number"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment>Kr </InputAdornment>,
                  }}
                />
                <Input
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  field="description"
                  label="Beskrivning"
                  rows={7}
                  multiline
                  fullWidth
                />
                <Box mt={1} textAlign="right">
                  <Button
                    onClick={() => toggleOptionDialogOpen()}
                    color="primary"
                  >
                    Avbryt
                  </Button>
                  <Button type="submit" disabled={isSubmitting} color="primary">
                    {edit ? "Ändra" : "Skapa"}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Dialog>
    )
  );
}
